import {
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BLink,
  BModal,
  BFormText,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardBody,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BCard,
  BInputGroupPrepend,
  BTable,
  BTab,
  BTabs,
  BFormRadio,
  BSidebar,
  BCardHeader,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormInvalidFeedback,
  BMedia,
  BMediaBody,
  BMediaAside,
  BFormFile,
  BFormTextarea,
  BOverlay,
  BPagination,
  BPaginationNav,
  BFormDatepicker,
  BFormTimepicker,
  BFormSelect,
  BAlert,
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BPopover,
  BCalendar,
  BCollapse,
  BTableLite,
  VBPopover,
  VBTooltip,
  VBToggle, VBModal,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import InputMask from 'vue-input-mask'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { VTree } from 'vue-tree-halower'
import InitFileManager from '@/components/InitFileManager'
import draggable from 'vuedraggable'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

const GlobalMixin = {
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BLink,
    BModal,
    BFormText,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardBody,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BCard,
    BInputGroupPrepend,
    BTable,
    BTab,
    BTabs,
    BCardHeader,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormRadio,
    BSidebar,
    BFormInvalidFeedback,
    BMedia,
    BMediaBody,
    BMediaAside,
    BFormFile,
    BFormTextarea,
    BOverlay,
    BPagination,
    BPaginationNav,
    BFormDatepicker,
    BFormTimepicker,
    BFormSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BPopover,
    BCalendar,
    BCollapse,
    BTableLite,
    vSelect,
    VTree,
    InputMask,
    InitFileManager,
    draggable,
    Treeselect,
  },
  data() {
    return {
      statusOptions: [
        { label: this.$t('general.statuses.enabled'), value: 'enabled' },
        { label: this.$t('general.statuses.disabled'), value: 'disabled' },
      ],
      stickerTypeOptions: [
        { label: this.$t('general.sticker_types.default'), value: 'default' },
        { label: this.$t('general.sticker_types.icon'), value: 'icon' },
      ],
      genderOptions: [
        { label: this.$t('general.gender.male'), value: 'male' },
        { label: this.$t('general.gender.female'), value: 'female' },
      ],
      logicalOptions: [
        { label: this.$t('general.attribute_logical.and'), value: 'and' },
        { label: this.$t('general.attribute_logical.or'), value: 'or' },
      ],
      statusTypeOptions: [
        { label: this.$t('general.status_types.stock'), value: 'stock' },
        // { label: this.$t('general.status_types.order'), value: 'order' },
      ],
      statusLogicOptions: [
        { label: this.$t('general.status_logic.buy'), value: 'buy' },
        { label: this.$t('general.status_logic.not_in_stock'), value: 'not_in_stock' },
        { label: this.$t('general.status_logic.preorder'), value: 'preorder' },
        { label: this.$t('general.status_logic.disable'), value: 'disable' },
      ],
      pageTypeOptions: [
        { label: this.$t('general.pageTypes.Simple'), value: 'Simple' },
        { label: this.$t('general.pageTypes.Html'), value: 'Html' },
        { label: this.$t('general.pageTypes.Form'), value: 'Form' },
        { label: this.$t('general.pageTypes.Delivery'), value: 'Delivery' },
        { label: this.$t('general.pageTypes.Tradein'), value: 'Tradein' },
        { label: this.$t('general.pageTypes.Brands'), value: 'Brands' },
        { label: this.$t('general.pageTypes.Blog'), value: 'Blog' },
        { label: this.$t('general.pageTypes.Home'), value: 'Home' },
      ],
      statusOptionsFull: [
        { label: this.$t('general.statuses.enabled'), value: 'enabled' },
        { label: this.$t('general.statuses.disabled'), value: 'disabled' },
        { label: this.$t('general.statuses.pending'), value: 'pending' },
        { label: this.$t('general.statuses.approved'), value: 'approved' },
        { label: this.$t('general.statuses.rejected'), value: 'rejected' },
      ],
      extensionTypes: [
        { label: this.$t('general.extensionTypes.Products'), value: 'Products' },
        { label: this.$t('general.extensionTypes.Posts'), value: 'Posts' },
        { label: this.$t('general.extensionTypes.Banners'), value: 'Banners' },
        { label: this.$t('general.extensionTypes.Forms'), value: 'Forms' },
        { label: this.$t('general.extensionTypes.Html'), value: 'Html' },
        { label: this.$t('general.extensionTypes.Seo'), value: 'Seo' },
        { label: this.$t('general.extensionTypes.Faq'), value: 'Faq' },
        { label: this.$t('general.extensionTypes.Contacts'), value: 'Contacts' },
        { label: this.$t('general.extensionTypes.Reviews'), value: 'Reviews' },
        { label: this.$t('general.extensionTypes.Tabs'), value: 'Tabs' },
      ],
      extensionSubTypes: {
        Products: [
          { label: this.$t('general.extensionSubTypes.Default'), value: 'Default' },
          { label: this.$t('general.extensionSubTypes.Watched'), value: 'Watched' },
          { label: this.$t('general.extensionSubTypes.Newest'), value: 'Newest' },
          { label: this.$t('general.extensionSubTypes.Bestseller'), value: 'Bestseller' },
          { label: this.$t('general.extensionSubTypes.Sale'), value: 'Sale' },
          { label: this.$t('general.extensionSubTypes.Labeled'), value: 'Labeled' },
          { label: this.$t('general.extensionSubTypes.Categoryabled'), value: 'Categoryabled' },
        ],
        Posts: [
          { label: this.$t('general.extensionSubTypes.Default'), value: 'Default' },
        ],
        Banners: [
          { label: this.$t('general.extensionSubTypes.GeneralSlider'), value: 'GeneralSlider' },
          { label: this.$t('general.extensionSubTypes.BrandsBanner'), value: 'BrandsBanner' },
          { label: this.$t('general.extensionSubTypes.ThreeBanner'), value: 'ThreeBanner' },
          { label: this.$t('general.extensionSubTypes.PromoBanner'), value: 'PromoBanner' },
          { label: this.$t('general.extensionSubTypes.VideoBanner'), value: 'VideoBanner' },
          { label: this.$t('general.extensionSubTypes.AdvantagesBanner'), value: 'AdvantagesBanner' },
        ],
        Forms: [
          { label: this.$t('general.extensionSubTypes.Return'), value: 'Return' },
        ],
        Html: [
          { label: this.$t('general.extensionSubTypes.Default'), value: 'Default' },
        ],
        Seo: [
          { label: this.$t('general.extensionSubTypes.Default'), value: 'Default' },
        ],
        Faq: [
          { label: this.$t('general.extensionSubTypes.Default'), value: 'Default' },
        ],
        Menu: [
          { label: this.$t('general.extensionSubTypes.Default'), value: 'Default' },
        ],
        Reviews: [
          { label: this.$t('general.extensionSubTypes.Default'), value: 'Default' },
        ],
        Tabs: [
          { label: this.$t('general.extensionSubTypes.Default'), value: 'Default' },
        ],
        Contacts: [
          { label: this.$t('general.extensionSubTypes.Default'), value: 'Default' },
          { label: this.$t('general.extensionSubTypes.Map'), value: 'Map' },
        ],
      },
      deliveryTypes: [
        { label: this.$t('general.deliveryTypes.np_warehouse'), value: 'np_warehouse' },
        { label: this.$t('general.deliveryTypes.np_courier'), value: 'np_courier' },
        { label: this.$t('general.deliveryTypes.up_warehouse'), value: 'up_warehouse' },
        { label: this.$t('general.deliveryTypes.up_courier'), value: 'up_courier' },
        { label: this.$t('general.deliveryTypes.meest'), value: 'meest' },
        { label: this.$t('general.deliveryTypes.justin'), value: 'justin' },
        { label: this.$t('general.deliveryTypes.courier'), value: 'courier' },
        { label: this.$t('general.deliveryTypes.self_pickup'), value: 'self_pickup' },
      ],
      paymentTypes: [
        {
          label: this.$t('general.paymentTypes.cash'),
          value: 'cash',
          gateways: [],
        },
        {
          label: this.$t('general.paymentTypes.bank'),
          value: 'bank',
          gateways: [],
        },
        {
          label: this.$t('general.paymentTypes.bank_law'),
          value: 'bank_law',
          gateways: [],
        },
        {
          label: this.$t('general.paymentTypes.online'),
          value: 'online',
          gateways: [
            {
              label: this.$t('general.paymentGatewayTypes.card'),
              value: 'card',
              banks: [
                { label: this.$t('general.paymentGatewayBanks.privat'), value: 'privat' },
                { label: this.$t('general.paymentGatewayBanks.mono'), value: 'mono' },
              ],
            },
          ],
        },
        {
          label: this.$t('general.paymentTypes.part'),
          value: 'part',
          gateways: [
            {
              label: this.$t('general.paymentGatewayTypes.direct'),
              value: 'direct',
              banks: [
                { label: this.$t('general.paymentGatewayBanks.form'), value: 'form' },
                { label: this.$t('general.paymentGatewayBanks.iframe'), value: 'iframe' },
              ],
            },
            {
              label: this.$t('general.paymentGatewayTypes.card'),
              value: 'card',
              banks: [
                { label: `Slon | ${this.$t('general.paymentGatewayBanks.form_api')}`, value: 'slon' },
                { label: `Idea | ${this.$t('general.paymentGatewayBanks.form_api')}`, value: 'idea' },
                { label: this.$t('general.paymentGatewayBanks.mono'), value: 'mono' },
                { label: this.$t('general.paymentGatewayBanks.privat'), value: 'privat' },
              ],
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    confirm(callback, options) {
      const data = {
        title: this.$t('notifications.warning'),
        text: this.$t('notifications.confirm.destroy.text'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('notifications.confirm.destroy.buttons.cancel'),
        confirmButtonText: this.$t('notifications.confirm.destroy.buttons.yes'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        ...options || {},
      }

      this.$swal(data).then(result => {
        if (result.isConfirmed) {
          callback(result)
        }
      })
    },
  },
}

export default GlobalMixin
